























































































































import { Component, Vue } from 'vue-property-decorator'
import NavMenuBar from '@/components/NavMenuBar.vue'
import HelpCenterService from '@/services/HelpCenterService'

@Component({ components: { NavMenuBar } })
export default class HelpCenter extends Vue {
  private categoryList: HelpCenterVM[] = []
  private eHelpCenteType: any = EHelpCenteType

  created() {
    this.getHelpCenterService()
  }

  getHelpCenterService() {
    HelpCenterService.getHelpCenterCategory().then((res: any) => {
      if (res.status === 200) {
        this.categoryList = res.data
      }
    })
  }
}

interface HelpCenterVM {
  id: number
  name: string
  title: any
  content: any
  parent_id: any
  sort: number
  type: EHelpCenteType
  created_at: string
  updated_at: string
  deleted_at: any
  child_list: HelpCenterChild[]
}

interface HelpCenterChild {
  id: number
  name: any
  title: string
  content: string
  parent_id: number
  sort: number
  type: EHelpCenteType
  created_at: string
  updated_at: string
  deleted_at: any
  page_list: any[]
}

enum EHelpCenteType {
  PARENT = 1,
  CHILD = 2
}
